.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }
  
  .modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  .role-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .role-button {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .role-button:hover {
    background-color: #f9f9f9;
  }
  
  .icon {
    margin-right: 10px;
    width: 30px;
    color: #888;
  }
  
  .role-title {
    font-weight: 600;
    text-align: left;
  }
  
  .role-description {
    font-size: 0.9em;
    color: #555;
    margin-top: -10px;
  }
  